import { render, staticRenderFns } from "./BaseGoogleAdsIntegration.vue?vue&type=template&id=7ee0bacb&scoped=true"
import script from "./BaseGoogleAdsIntegration.vue?vue&type=script&lang=js"
export * from "./BaseGoogleAdsIntegration.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ee0bacb",
  null
  
)

export default component.exports