<template>
  <div>
    <base-google-ads-integration
      :has-consent="hasConsent"
      :accounts-list="accountsList"
      :connected-account="connectedAccount"
      :is-loading="isLoading"
      @verify-token="verifyToken"
      @google-ads-account-connected="googleAdsAccountConnected"
    />
  </div>
</template>

<script>
import BaseGoogleAdsIntegration from '@/components/google/ads/BaseGoogleAdsIntegration.vue'
import googleConfig from '@/configs/google-client'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'AuditGoogleAdsIntegration',
  components: {
    BaseGoogleAdsIntegration,
  },
  data() {
    return {
      isLoading: false,
      accountsList: [],
      connectedAccount: {},
      hasConsent: false,
      googleConfig: googleConfig,
    }
  },
  computed: {
    ...mapGetters({
      userAuditGoogleCredentials: 'auth/userAuditGoogleCredentials',
    }),
  },
  async mounted() {
    if (!this.userAuditGoogleCredentials) {
      this.isLoading = false
      return
    }
    this.isLoading = true
    try {
      this.hasConsent = await this.checkConsent()
      if (this.hasConsent) {
        await this.loadGoogleAvailableAccounts()
        if (this.accountsList) {
          await this.loadConnectedGoogleAdsAccount()
        }
      }
    } catch (error) {
      console.log(error)
    }
    this.isLoading = false
  },
  methods: {
    ...mapActions({
      setAuditCredentials: 'auth/setAuditCredentials',
    }),
    async checkConsent() {
      try {
        const consentResponse = await this.$http.get(`audit/integration/google/ads/check-consent`)
        return consentResponse.data
      } catch (e) {
        return false
      }
    },
    async verifyToken(response) {
      try {
        this.isLoading = true
        this.accountsList = []
        this.connectedAccount = {}
        let result = await this.$http.post(`audit/integration/google/ads/grant`, {
          api_token: response.code,
        })
        await this.setAuditCredentials({
          email: result.data.user.email,
          name: result.data.user.name,
        })
        this.hasConsent = true
        await this.loadGoogleAvailableAccounts()
        this.$emit('integration-finished')
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    async googleAdsAccountConnected(googleAdsAccount) {
      this.connectedAccount = googleAdsAccount
      console.log(googleAdsAccount)

      await this.$http.post(`audit/integration/google/ads`, {
        googleRootCustomerId: googleAdsAccount.parent_id,
        googleCustomerId: googleAdsAccount.id,
      })

      await this.setAuditCredentials({
        googleCustomerId: googleAdsAccount.id,
        googleCustomerName: googleAdsAccount.name,
      })

      this.$emit('account-connected', true)
    },
    async loadGoogleAvailableAccounts() {
      try {
        const resp = await this.$http.get(`audit/integration/google/ads`)
        this.accountsList = resp.data.data.filter(item => !item.manager)
      } catch (error) {
        console.log(error)
        this.accountsList = [] // Reset on error
      }
    },
    async loadConnectedGoogleAdsAccount() {
      try {
        this.connectedAccount = this.accountsList.find(
          item => item.id === this.userAuditGoogleCredentials.googleCustomerId,
        )
        this.$emit('account-connected', true)
      } catch (error) {
        console.log(error)
      }
    },
  },
}
</script>
