<template>
  <div>
    <OnboardingLayout sub-title="with BrightBid Audit">
      <div class="my-6 max-w-500 min-w-full flex flex-col">
        <google-ads-integration />
      </div>
    </OnboardingLayout>
  </div>
</template>

<script>
import OnboardingLayout from '@/layouts/onboarding'
import GoogleAdsIntegration from '@/components/audit-components/GoogleAdsIntegration.vue'

export default {
  name: 'AuditConnectGoogleAds',
  components: {
    GoogleAdsIntegration,
    OnboardingLayout,
  },
  data() {
    return {}
  },
}
</script>
